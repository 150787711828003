
export enum CryptoCode {
    CRYPTO_UNSPECIFIED = 0,
    BTC = 1,
    ETH = 2,
    MATIC = 15,
    COFBR = 100,
}

export enum MoneyCode {
    MONEY_UNSPECIFIED = 0,
    BRL = 1,
    USD = 2,
    EUR = 3,
}
/**
 * @fileoverview gRPC-Web generated client stub for qis.transaction.v1
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_api_annotations_pb = require('../../../google/api/annotations_pb.js')

var qis_transaction_v1_pix_pb = require('../../../qis/transaction/v1/pix_pb.js')

var qis_transaction_v1_transaction_pb = require('../../../qis/transaction/v1/transaction_pb.js')
const proto = {};
proto.qis = {};
proto.qis.transaction = {};
proto.qis.transaction.v1 = require('./transaction_api_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.qis.transaction.v1.TransactionServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.qis.transaction.v1.TransactionServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.qis.transaction.v1.CreateInvoicePIXForRFQRequest,
 *   !proto.qis.transaction.v1.CreateInvoicePIXForRFQResponse>}
 */
const methodDescriptor_TransactionService_CreateInvoicePIXForRFQ = new grpc.web.MethodDescriptor(
  '/qis.transaction.v1.TransactionService/CreateInvoicePIXForRFQ',
  grpc.web.MethodType.UNARY,
  proto.qis.transaction.v1.CreateInvoicePIXForRFQRequest,
  proto.qis.transaction.v1.CreateInvoicePIXForRFQResponse,
  /**
   * @param {!proto.qis.transaction.v1.CreateInvoicePIXForRFQRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.qis.transaction.v1.CreateInvoicePIXForRFQResponse.deserializeBinary
);


/**
 * @param {!proto.qis.transaction.v1.CreateInvoicePIXForRFQRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.qis.transaction.v1.CreateInvoicePIXForRFQResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.qis.transaction.v1.CreateInvoicePIXForRFQResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.qis.transaction.v1.TransactionServiceClient.prototype.createInvoicePIXForRFQ =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/qis.transaction.v1.TransactionService/CreateInvoicePIXForRFQ',
      request,
      metadata || {},
      methodDescriptor_TransactionService_CreateInvoicePIXForRFQ,
      callback);
};


/**
 * @param {!proto.qis.transaction.v1.CreateInvoicePIXForRFQRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.qis.transaction.v1.CreateInvoicePIXForRFQResponse>}
 *     Promise that resolves to the response
 */
proto.qis.transaction.v1.TransactionServicePromiseClient.prototype.createInvoicePIXForRFQ =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/qis.transaction.v1.TransactionService/CreateInvoicePIXForRFQ',
      request,
      metadata || {},
      methodDescriptor_TransactionService_CreateInvoicePIXForRFQ);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.qis.transaction.v1.FulFilTransactionRequest,
 *   !proto.qis.transaction.v1.FulFillTransactionResponse>}
 */
const methodDescriptor_TransactionService_FulFillTransaction = new grpc.web.MethodDescriptor(
  '/qis.transaction.v1.TransactionService/FulFillTransaction',
  grpc.web.MethodType.UNARY,
  proto.qis.transaction.v1.FulFilTransactionRequest,
  proto.qis.transaction.v1.FulFillTransactionResponse,
  /**
   * @param {!proto.qis.transaction.v1.FulFilTransactionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.qis.transaction.v1.FulFillTransactionResponse.deserializeBinary
);


/**
 * @param {!proto.qis.transaction.v1.FulFilTransactionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.qis.transaction.v1.FulFillTransactionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.qis.transaction.v1.FulFillTransactionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.qis.transaction.v1.TransactionServiceClient.prototype.fulFillTransaction =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/qis.transaction.v1.TransactionService/FulFillTransaction',
      request,
      metadata || {},
      methodDescriptor_TransactionService_FulFillTransaction,
      callback);
};


/**
 * @param {!proto.qis.transaction.v1.FulFilTransactionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.qis.transaction.v1.FulFillTransactionResponse>}
 *     Promise that resolves to the response
 */
proto.qis.transaction.v1.TransactionServicePromiseClient.prototype.fulFillTransaction =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/qis.transaction.v1.TransactionService/FulFillTransaction',
      request,
      metadata || {},
      methodDescriptor_TransactionService_FulFillTransaction);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.qis.transaction.v1.GetTransactionRequest,
 *   !proto.qis.transaction.v1.GetTransactionResponse>}
 */
const methodDescriptor_TransactionService_GetTransaction = new grpc.web.MethodDescriptor(
  '/qis.transaction.v1.TransactionService/GetTransaction',
  grpc.web.MethodType.UNARY,
  proto.qis.transaction.v1.GetTransactionRequest,
  proto.qis.transaction.v1.GetTransactionResponse,
  /**
   * @param {!proto.qis.transaction.v1.GetTransactionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.qis.transaction.v1.GetTransactionResponse.deserializeBinary
);


/**
 * @param {!proto.qis.transaction.v1.GetTransactionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.qis.transaction.v1.GetTransactionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.qis.transaction.v1.GetTransactionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.qis.transaction.v1.TransactionServiceClient.prototype.getTransaction =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/qis.transaction.v1.TransactionService/GetTransaction',
      request,
      metadata || {},
      methodDescriptor_TransactionService_GetTransaction,
      callback);
};


/**
 * @param {!proto.qis.transaction.v1.GetTransactionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.qis.transaction.v1.GetTransactionResponse>}
 *     Promise that resolves to the response
 */
proto.qis.transaction.v1.TransactionServicePromiseClient.prototype.getTransaction =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/qis.transaction.v1.TransactionService/GetTransaction',
      request,
      metadata || {},
      methodDescriptor_TransactionService_GetTransaction);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.qis.transaction.v1.GetTransactionByExternalIdRequest,
 *   !proto.qis.transaction.v1.GetTransactionResponse>}
 */
const methodDescriptor_TransactionService_GetTransactionByExternalId = new grpc.web.MethodDescriptor(
  '/qis.transaction.v1.TransactionService/GetTransactionByExternalId',
  grpc.web.MethodType.UNARY,
  proto.qis.transaction.v1.GetTransactionByExternalIdRequest,
  proto.qis.transaction.v1.GetTransactionResponse,
  /**
   * @param {!proto.qis.transaction.v1.GetTransactionByExternalIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.qis.transaction.v1.GetTransactionResponse.deserializeBinary
);


/**
 * @param {!proto.qis.transaction.v1.GetTransactionByExternalIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.qis.transaction.v1.GetTransactionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.qis.transaction.v1.GetTransactionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.qis.transaction.v1.TransactionServiceClient.prototype.getTransactionByExternalId =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/qis.transaction.v1.TransactionService/GetTransactionByExternalId',
      request,
      metadata || {},
      methodDescriptor_TransactionService_GetTransactionByExternalId,
      callback);
};


/**
 * @param {!proto.qis.transaction.v1.GetTransactionByExternalIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.qis.transaction.v1.GetTransactionResponse>}
 *     Promise that resolves to the response
 */
proto.qis.transaction.v1.TransactionServicePromiseClient.prototype.getTransactionByExternalId =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/qis.transaction.v1.TransactionService/GetTransactionByExternalId',
      request,
      metadata || {},
      methodDescriptor_TransactionService_GetTransactionByExternalId);
};


module.exports = proto.qis.transaction.v1;


let dateFormat = 'd/m/Y'  

export const configNoMinDate = {
    disable: [],
    altFormat: dateFormat,
    altInput: true,
    allowInput: true,
    defaultDate: 'today',
}

export const config = {
    disable: [
      function (date) {
          return (date.getDay() == 0 || date.getDay() == 6)
      }
    ],
    altFormat: dateFormat,
    altInput: true,
    allowInput: true,
    minDate: new Date().toISOString().slice(0, 10)
}
// source: qis/rfq/v1/rfq_api.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_api_annotations_pb = require('../../../google/api/annotations_pb.js');
goog.object.extend(proto, google_api_annotations_pb);
var qis_rfq_v1_rfq_pb = require('../../../qis/rfq/v1/rfq_pb.js');
goog.object.extend(proto, qis_rfq_v1_rfq_pb);
var qis_product_v1_product_pb = require('../../../qis/product/v1/product_pb.js');
goog.object.extend(proto, qis_product_v1_product_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var qis_parties_v1_party_pb = require('../../../qis/parties/v1/party_pb.js');
goog.object.extend(proto, qis_parties_v1_party_pb);
var qis_parties_v1_counterparty_pb = require('../../../qis/parties/v1/counterparty_pb.js');
goog.object.extend(proto, qis_parties_v1_counterparty_pb);
goog.exportSymbol('proto.qis.rfq.v1.CreateRFQRequest', null, global);
goog.exportSymbol('proto.qis.rfq.v1.CreateRFQResponse', null, global);
goog.exportSymbol('proto.qis.rfq.v1.CreateTaxReceiptRequest', null, global);
goog.exportSymbol('proto.qis.rfq.v1.CreateTaxReceiptResponse', null, global);
goog.exportSymbol('proto.qis.rfq.v1.GetListRFQResponse', null, global);
goog.exportSymbol('proto.qis.rfq.v1.GetListRFQResquest', null, global);
goog.exportSymbol('proto.qis.rfq.v1.GetRFQRequest', null, global);
goog.exportSymbol('proto.qis.rfq.v1.GetRFQResponse', null, global);
goog.exportSymbol('proto.qis.rfq.v1.UpdateRFQRequest', null, global);
goog.exportSymbol('proto.qis.rfq.v1.UpdateRFQResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.rfq.v1.CreateTaxReceiptRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.qis.rfq.v1.CreateTaxReceiptRequest.repeatedFields_, null);
};
goog.inherits(proto.qis.rfq.v1.CreateTaxReceiptRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.rfq.v1.CreateTaxReceiptRequest.displayName = 'proto.qis.rfq.v1.CreateTaxReceiptRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.rfq.v1.CreateTaxReceiptResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.qis.rfq.v1.CreateTaxReceiptResponse.repeatedFields_, null);
};
goog.inherits(proto.qis.rfq.v1.CreateTaxReceiptResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.rfq.v1.CreateTaxReceiptResponse.displayName = 'proto.qis.rfq.v1.CreateTaxReceiptResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.rfq.v1.CreateRFQRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qis.rfq.v1.CreateRFQRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.rfq.v1.CreateRFQRequest.displayName = 'proto.qis.rfq.v1.CreateRFQRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.rfq.v1.UpdateRFQRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qis.rfq.v1.UpdateRFQRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.rfq.v1.UpdateRFQRequest.displayName = 'proto.qis.rfq.v1.UpdateRFQRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.rfq.v1.CreateRFQResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qis.rfq.v1.CreateRFQResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.rfq.v1.CreateRFQResponse.displayName = 'proto.qis.rfq.v1.CreateRFQResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.rfq.v1.UpdateRFQResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qis.rfq.v1.UpdateRFQResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.rfq.v1.UpdateRFQResponse.displayName = 'proto.qis.rfq.v1.UpdateRFQResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.rfq.v1.GetRFQRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qis.rfq.v1.GetRFQRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.rfq.v1.GetRFQRequest.displayName = 'proto.qis.rfq.v1.GetRFQRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.rfq.v1.GetRFQResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qis.rfq.v1.GetRFQResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.rfq.v1.GetRFQResponse.displayName = 'proto.qis.rfq.v1.GetRFQResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.rfq.v1.GetListRFQResquest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qis.rfq.v1.GetListRFQResquest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.rfq.v1.GetListRFQResquest.displayName = 'proto.qis.rfq.v1.GetListRFQResquest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.rfq.v1.GetListRFQResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.qis.rfq.v1.GetListRFQResponse.repeatedFields_, null);
};
goog.inherits(proto.qis.rfq.v1.GetListRFQResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.rfq.v1.GetListRFQResponse.displayName = 'proto.qis.rfq.v1.GetListRFQResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.qis.rfq.v1.CreateTaxReceiptRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.rfq.v1.CreateTaxReceiptRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.rfq.v1.CreateTaxReceiptRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.rfq.v1.CreateTaxReceiptRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.rfq.v1.CreateTaxReceiptRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    rfqIdList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.rfq.v1.CreateTaxReceiptRequest}
 */
proto.qis.rfq.v1.CreateTaxReceiptRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.rfq.v1.CreateTaxReceiptRequest;
  return proto.qis.rfq.v1.CreateTaxReceiptRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.rfq.v1.CreateTaxReceiptRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.rfq.v1.CreateTaxReceiptRequest}
 */
proto.qis.rfq.v1.CreateTaxReceiptRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addRfqId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.rfq.v1.CreateTaxReceiptRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.rfq.v1.CreateTaxReceiptRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.rfq.v1.CreateTaxReceiptRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.rfq.v1.CreateTaxReceiptRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRfqIdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string rfq_id = 1;
 * @return {!Array<string>}
 */
proto.qis.rfq.v1.CreateTaxReceiptRequest.prototype.getRfqIdList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.qis.rfq.v1.CreateTaxReceiptRequest} returns this
 */
proto.qis.rfq.v1.CreateTaxReceiptRequest.prototype.setRfqIdList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.qis.rfq.v1.CreateTaxReceiptRequest} returns this
 */
proto.qis.rfq.v1.CreateTaxReceiptRequest.prototype.addRfqId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qis.rfq.v1.CreateTaxReceiptRequest} returns this
 */
proto.qis.rfq.v1.CreateTaxReceiptRequest.prototype.clearRfqIdList = function() {
  return this.setRfqIdList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.qis.rfq.v1.CreateTaxReceiptResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.rfq.v1.CreateTaxReceiptResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.rfq.v1.CreateTaxReceiptResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.rfq.v1.CreateTaxReceiptResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.rfq.v1.CreateTaxReceiptResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    rfqIdList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.rfq.v1.CreateTaxReceiptResponse}
 */
proto.qis.rfq.v1.CreateTaxReceiptResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.rfq.v1.CreateTaxReceiptResponse;
  return proto.qis.rfq.v1.CreateTaxReceiptResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.rfq.v1.CreateTaxReceiptResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.rfq.v1.CreateTaxReceiptResponse}
 */
proto.qis.rfq.v1.CreateTaxReceiptResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addRfqId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.rfq.v1.CreateTaxReceiptResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.rfq.v1.CreateTaxReceiptResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.rfq.v1.CreateTaxReceiptResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.rfq.v1.CreateTaxReceiptResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRfqIdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string rfq_id = 1;
 * @return {!Array<string>}
 */
proto.qis.rfq.v1.CreateTaxReceiptResponse.prototype.getRfqIdList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.qis.rfq.v1.CreateTaxReceiptResponse} returns this
 */
proto.qis.rfq.v1.CreateTaxReceiptResponse.prototype.setRfqIdList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.qis.rfq.v1.CreateTaxReceiptResponse} returns this
 */
proto.qis.rfq.v1.CreateTaxReceiptResponse.prototype.addRfqId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qis.rfq.v1.CreateTaxReceiptResponse} returns this
 */
proto.qis.rfq.v1.CreateTaxReceiptResponse.prototype.clearRfqIdList = function() {
  return this.setRfqIdList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.rfq.v1.CreateRFQRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.rfq.v1.CreateRFQRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.rfq.v1.CreateRFQRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.rfq.v1.CreateRFQRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    product: (f = msg.getProduct()) && qis_product_v1_product_pb.Product.toObject(includeInstance, f),
    neededQuantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    isIndicative: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    party: (f = msg.getParty()) && qis_parties_v1_party_pb.Party.toObject(includeInstance, f),
    counterParty: (f = msg.getCounterParty()) && qis_parties_v1_counterparty_pb.Counterparty.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.rfq.v1.CreateRFQRequest}
 */
proto.qis.rfq.v1.CreateRFQRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.rfq.v1.CreateRFQRequest;
  return proto.qis.rfq.v1.CreateRFQRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.rfq.v1.CreateRFQRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.rfq.v1.CreateRFQRequest}
 */
proto.qis.rfq.v1.CreateRFQRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new qis_product_v1_product_pb.Product;
      reader.readMessage(value,qis_product_v1_product_pb.Product.deserializeBinaryFromReader);
      msg.setProduct(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setNeededQuantity(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsIndicative(value);
      break;
    case 4:
      var value = new qis_parties_v1_party_pb.Party;
      reader.readMessage(value,qis_parties_v1_party_pb.Party.deserializeBinaryFromReader);
      msg.setParty(value);
      break;
    case 5:
      var value = new qis_parties_v1_counterparty_pb.Counterparty;
      reader.readMessage(value,qis_parties_v1_counterparty_pb.Counterparty.deserializeBinaryFromReader);
      msg.setCounterParty(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.rfq.v1.CreateRFQRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.rfq.v1.CreateRFQRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.rfq.v1.CreateRFQRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.rfq.v1.CreateRFQRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProduct();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      qis_product_v1_product_pb.Product.serializeBinaryToWriter
    );
  }
  f = message.getNeededQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getIsIndicative();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getParty();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      qis_parties_v1_party_pb.Party.serializeBinaryToWriter
    );
  }
  f = message.getCounterParty();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      qis_parties_v1_counterparty_pb.Counterparty.serializeBinaryToWriter
    );
  }
};


/**
 * optional qis.product.v1.Product product = 1;
 * @return {?proto.qis.product.v1.Product}
 */
proto.qis.rfq.v1.CreateRFQRequest.prototype.getProduct = function() {
  return /** @type{?proto.qis.product.v1.Product} */ (
    jspb.Message.getWrapperField(this, qis_product_v1_product_pb.Product, 1));
};


/**
 * @param {?proto.qis.product.v1.Product|undefined} value
 * @return {!proto.qis.rfq.v1.CreateRFQRequest} returns this
*/
proto.qis.rfq.v1.CreateRFQRequest.prototype.setProduct = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.rfq.v1.CreateRFQRequest} returns this
 */
proto.qis.rfq.v1.CreateRFQRequest.prototype.clearProduct = function() {
  return this.setProduct(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.rfq.v1.CreateRFQRequest.prototype.hasProduct = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional double needed_quantity = 2;
 * @return {number}
 */
proto.qis.rfq.v1.CreateRFQRequest.prototype.getNeededQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.qis.rfq.v1.CreateRFQRequest} returns this
 */
proto.qis.rfq.v1.CreateRFQRequest.prototype.setNeededQuantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional bool is_indicative = 3;
 * @return {boolean}
 */
proto.qis.rfq.v1.CreateRFQRequest.prototype.getIsIndicative = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qis.rfq.v1.CreateRFQRequest} returns this
 */
proto.qis.rfq.v1.CreateRFQRequest.prototype.setIsIndicative = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional qis.parties.v1.Party party = 4;
 * @return {?proto.qis.parties.v1.Party}
 */
proto.qis.rfq.v1.CreateRFQRequest.prototype.getParty = function() {
  return /** @type{?proto.qis.parties.v1.Party} */ (
    jspb.Message.getWrapperField(this, qis_parties_v1_party_pb.Party, 4));
};


/**
 * @param {?proto.qis.parties.v1.Party|undefined} value
 * @return {!proto.qis.rfq.v1.CreateRFQRequest} returns this
*/
proto.qis.rfq.v1.CreateRFQRequest.prototype.setParty = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.rfq.v1.CreateRFQRequest} returns this
 */
proto.qis.rfq.v1.CreateRFQRequest.prototype.clearParty = function() {
  return this.setParty(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.rfq.v1.CreateRFQRequest.prototype.hasParty = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional qis.parties.v1.Counterparty counter_party = 5;
 * @return {?proto.qis.parties.v1.Counterparty}
 */
proto.qis.rfq.v1.CreateRFQRequest.prototype.getCounterParty = function() {
  return /** @type{?proto.qis.parties.v1.Counterparty} */ (
    jspb.Message.getWrapperField(this, qis_parties_v1_counterparty_pb.Counterparty, 5));
};


/**
 * @param {?proto.qis.parties.v1.Counterparty|undefined} value
 * @return {!proto.qis.rfq.v1.CreateRFQRequest} returns this
*/
proto.qis.rfq.v1.CreateRFQRequest.prototype.setCounterParty = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.rfq.v1.CreateRFQRequest} returns this
 */
proto.qis.rfq.v1.CreateRFQRequest.prototype.clearCounterParty = function() {
  return this.setCounterParty(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.rfq.v1.CreateRFQRequest.prototype.hasCounterParty = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.rfq.v1.UpdateRFQRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.rfq.v1.UpdateRFQRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.rfq.v1.UpdateRFQRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.rfq.v1.UpdateRFQRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    rfqId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    product: (f = msg.getProduct()) && qis_product_v1_product_pb.Product.toObject(includeInstance, f),
    neededQuantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    counterParty: (f = msg.getCounterParty()) && qis_parties_v1_counterparty_pb.Counterparty.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.rfq.v1.UpdateRFQRequest}
 */
proto.qis.rfq.v1.UpdateRFQRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.rfq.v1.UpdateRFQRequest;
  return proto.qis.rfq.v1.UpdateRFQRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.rfq.v1.UpdateRFQRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.rfq.v1.UpdateRFQRequest}
 */
proto.qis.rfq.v1.UpdateRFQRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRfqId(value);
      break;
    case 2:
      var value = new qis_product_v1_product_pb.Product;
      reader.readMessage(value,qis_product_v1_product_pb.Product.deserializeBinaryFromReader);
      msg.setProduct(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setNeededQuantity(value);
      break;
    case 4:
      var value = new qis_parties_v1_counterparty_pb.Counterparty;
      reader.readMessage(value,qis_parties_v1_counterparty_pb.Counterparty.deserializeBinaryFromReader);
      msg.setCounterParty(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.rfq.v1.UpdateRFQRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.rfq.v1.UpdateRFQRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.rfq.v1.UpdateRFQRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.rfq.v1.UpdateRFQRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRfqId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProduct();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      qis_product_v1_product_pb.Product.serializeBinaryToWriter
    );
  }
  f = message.getNeededQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getCounterParty();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      qis_parties_v1_counterparty_pb.Counterparty.serializeBinaryToWriter
    );
  }
};


/**
 * optional string rfq_id = 1;
 * @return {string}
 */
proto.qis.rfq.v1.UpdateRFQRequest.prototype.getRfqId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.rfq.v1.UpdateRFQRequest} returns this
 */
proto.qis.rfq.v1.UpdateRFQRequest.prototype.setRfqId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional qis.product.v1.Product product = 2;
 * @return {?proto.qis.product.v1.Product}
 */
proto.qis.rfq.v1.UpdateRFQRequest.prototype.getProduct = function() {
  return /** @type{?proto.qis.product.v1.Product} */ (
    jspb.Message.getWrapperField(this, qis_product_v1_product_pb.Product, 2));
};


/**
 * @param {?proto.qis.product.v1.Product|undefined} value
 * @return {!proto.qis.rfq.v1.UpdateRFQRequest} returns this
*/
proto.qis.rfq.v1.UpdateRFQRequest.prototype.setProduct = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.rfq.v1.UpdateRFQRequest} returns this
 */
proto.qis.rfq.v1.UpdateRFQRequest.prototype.clearProduct = function() {
  return this.setProduct(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.rfq.v1.UpdateRFQRequest.prototype.hasProduct = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional double needed_quantity = 3;
 * @return {number}
 */
proto.qis.rfq.v1.UpdateRFQRequest.prototype.getNeededQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.qis.rfq.v1.UpdateRFQRequest} returns this
 */
proto.qis.rfq.v1.UpdateRFQRequest.prototype.setNeededQuantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional qis.parties.v1.Counterparty counter_party = 4;
 * @return {?proto.qis.parties.v1.Counterparty}
 */
proto.qis.rfq.v1.UpdateRFQRequest.prototype.getCounterParty = function() {
  return /** @type{?proto.qis.parties.v1.Counterparty} */ (
    jspb.Message.getWrapperField(this, qis_parties_v1_counterparty_pb.Counterparty, 4));
};


/**
 * @param {?proto.qis.parties.v1.Counterparty|undefined} value
 * @return {!proto.qis.rfq.v1.UpdateRFQRequest} returns this
*/
proto.qis.rfq.v1.UpdateRFQRequest.prototype.setCounterParty = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.rfq.v1.UpdateRFQRequest} returns this
 */
proto.qis.rfq.v1.UpdateRFQRequest.prototype.clearCounterParty = function() {
  return this.setCounterParty(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.rfq.v1.UpdateRFQRequest.prototype.hasCounterParty = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.rfq.v1.CreateRFQResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.rfq.v1.CreateRFQResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.rfq.v1.CreateRFQResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.rfq.v1.CreateRFQResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    rfq: (f = msg.getRfq()) && qis_rfq_v1_rfq_pb.RFQ.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.rfq.v1.CreateRFQResponse}
 */
proto.qis.rfq.v1.CreateRFQResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.rfq.v1.CreateRFQResponse;
  return proto.qis.rfq.v1.CreateRFQResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.rfq.v1.CreateRFQResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.rfq.v1.CreateRFQResponse}
 */
proto.qis.rfq.v1.CreateRFQResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new qis_rfq_v1_rfq_pb.RFQ;
      reader.readMessage(value,qis_rfq_v1_rfq_pb.RFQ.deserializeBinaryFromReader);
      msg.setRfq(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.rfq.v1.CreateRFQResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.rfq.v1.CreateRFQResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.rfq.v1.CreateRFQResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.rfq.v1.CreateRFQResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRfq();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      qis_rfq_v1_rfq_pb.RFQ.serializeBinaryToWriter
    );
  }
};


/**
 * optional RFQ rfq = 1;
 * @return {?proto.qis.rfq.v1.RFQ}
 */
proto.qis.rfq.v1.CreateRFQResponse.prototype.getRfq = function() {
  return /** @type{?proto.qis.rfq.v1.RFQ} */ (
    jspb.Message.getWrapperField(this, qis_rfq_v1_rfq_pb.RFQ, 1));
};


/**
 * @param {?proto.qis.rfq.v1.RFQ|undefined} value
 * @return {!proto.qis.rfq.v1.CreateRFQResponse} returns this
*/
proto.qis.rfq.v1.CreateRFQResponse.prototype.setRfq = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.rfq.v1.CreateRFQResponse} returns this
 */
proto.qis.rfq.v1.CreateRFQResponse.prototype.clearRfq = function() {
  return this.setRfq(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.rfq.v1.CreateRFQResponse.prototype.hasRfq = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.rfq.v1.UpdateRFQResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.rfq.v1.UpdateRFQResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.rfq.v1.UpdateRFQResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.rfq.v1.UpdateRFQResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    rfq: (f = msg.getRfq()) && qis_rfq_v1_rfq_pb.RFQ.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.rfq.v1.UpdateRFQResponse}
 */
proto.qis.rfq.v1.UpdateRFQResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.rfq.v1.UpdateRFQResponse;
  return proto.qis.rfq.v1.UpdateRFQResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.rfq.v1.UpdateRFQResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.rfq.v1.UpdateRFQResponse}
 */
proto.qis.rfq.v1.UpdateRFQResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new qis_rfq_v1_rfq_pb.RFQ;
      reader.readMessage(value,qis_rfq_v1_rfq_pb.RFQ.deserializeBinaryFromReader);
      msg.setRfq(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.rfq.v1.UpdateRFQResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.rfq.v1.UpdateRFQResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.rfq.v1.UpdateRFQResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.rfq.v1.UpdateRFQResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRfq();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      qis_rfq_v1_rfq_pb.RFQ.serializeBinaryToWriter
    );
  }
};


/**
 * optional RFQ rfq = 1;
 * @return {?proto.qis.rfq.v1.RFQ}
 */
proto.qis.rfq.v1.UpdateRFQResponse.prototype.getRfq = function() {
  return /** @type{?proto.qis.rfq.v1.RFQ} */ (
    jspb.Message.getWrapperField(this, qis_rfq_v1_rfq_pb.RFQ, 1));
};


/**
 * @param {?proto.qis.rfq.v1.RFQ|undefined} value
 * @return {!proto.qis.rfq.v1.UpdateRFQResponse} returns this
*/
proto.qis.rfq.v1.UpdateRFQResponse.prototype.setRfq = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.rfq.v1.UpdateRFQResponse} returns this
 */
proto.qis.rfq.v1.UpdateRFQResponse.prototype.clearRfq = function() {
  return this.setRfq(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.rfq.v1.UpdateRFQResponse.prototype.hasRfq = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.rfq.v1.GetRFQRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.rfq.v1.GetRFQRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.rfq.v1.GetRFQRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.rfq.v1.GetRFQRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    rfqId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.rfq.v1.GetRFQRequest}
 */
proto.qis.rfq.v1.GetRFQRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.rfq.v1.GetRFQRequest;
  return proto.qis.rfq.v1.GetRFQRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.rfq.v1.GetRFQRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.rfq.v1.GetRFQRequest}
 */
proto.qis.rfq.v1.GetRFQRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRfqId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.rfq.v1.GetRFQRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.rfq.v1.GetRFQRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.rfq.v1.GetRFQRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.rfq.v1.GetRFQRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRfqId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string rfq_id = 1;
 * @return {string}
 */
proto.qis.rfq.v1.GetRFQRequest.prototype.getRfqId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.rfq.v1.GetRFQRequest} returns this
 */
proto.qis.rfq.v1.GetRFQRequest.prototype.setRfqId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.rfq.v1.GetRFQResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.rfq.v1.GetRFQResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.rfq.v1.GetRFQResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.rfq.v1.GetRFQResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    rfq: (f = msg.getRfq()) && qis_rfq_v1_rfq_pb.RFQ.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.rfq.v1.GetRFQResponse}
 */
proto.qis.rfq.v1.GetRFQResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.rfq.v1.GetRFQResponse;
  return proto.qis.rfq.v1.GetRFQResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.rfq.v1.GetRFQResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.rfq.v1.GetRFQResponse}
 */
proto.qis.rfq.v1.GetRFQResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new qis_rfq_v1_rfq_pb.RFQ;
      reader.readMessage(value,qis_rfq_v1_rfq_pb.RFQ.deserializeBinaryFromReader);
      msg.setRfq(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.rfq.v1.GetRFQResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.rfq.v1.GetRFQResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.rfq.v1.GetRFQResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.rfq.v1.GetRFQResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRfq();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      qis_rfq_v1_rfq_pb.RFQ.serializeBinaryToWriter
    );
  }
};


/**
 * optional RFQ rfq = 1;
 * @return {?proto.qis.rfq.v1.RFQ}
 */
proto.qis.rfq.v1.GetRFQResponse.prototype.getRfq = function() {
  return /** @type{?proto.qis.rfq.v1.RFQ} */ (
    jspb.Message.getWrapperField(this, qis_rfq_v1_rfq_pb.RFQ, 1));
};


/**
 * @param {?proto.qis.rfq.v1.RFQ|undefined} value
 * @return {!proto.qis.rfq.v1.GetRFQResponse} returns this
*/
proto.qis.rfq.v1.GetRFQResponse.prototype.setRfq = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.rfq.v1.GetRFQResponse} returns this
 */
proto.qis.rfq.v1.GetRFQResponse.prototype.clearRfq = function() {
  return this.setRfq(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.rfq.v1.GetRFQResponse.prototype.hasRfq = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.rfq.v1.GetListRFQResquest.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.rfq.v1.GetListRFQResquest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.rfq.v1.GetListRFQResquest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.rfq.v1.GetListRFQResquest.toObject = function(includeInstance, msg) {
  var f, obj = {
    startDate: (f = msg.getStartDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endDate: (f = msg.getEndDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.rfq.v1.GetListRFQResquest}
 */
proto.qis.rfq.v1.GetListRFQResquest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.rfq.v1.GetListRFQResquest;
  return proto.qis.rfq.v1.GetListRFQResquest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.rfq.v1.GetListRFQResquest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.rfq.v1.GetListRFQResquest}
 */
proto.qis.rfq.v1.GetListRFQResquest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartDate(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.rfq.v1.GetListRFQResquest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.rfq.v1.GetListRFQResquest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.rfq.v1.GetListRFQResquest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.rfq.v1.GetListRFQResquest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp start_date = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qis.rfq.v1.GetListRFQResquest.prototype.getStartDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qis.rfq.v1.GetListRFQResquest} returns this
*/
proto.qis.rfq.v1.GetListRFQResquest.prototype.setStartDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.rfq.v1.GetListRFQResquest} returns this
 */
proto.qis.rfq.v1.GetListRFQResquest.prototype.clearStartDate = function() {
  return this.setStartDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.rfq.v1.GetListRFQResquest.prototype.hasStartDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp end_date = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qis.rfq.v1.GetListRFQResquest.prototype.getEndDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qis.rfq.v1.GetListRFQResquest} returns this
*/
proto.qis.rfq.v1.GetListRFQResquest.prototype.setEndDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.rfq.v1.GetListRFQResquest} returns this
 */
proto.qis.rfq.v1.GetListRFQResquest.prototype.clearEndDate = function() {
  return this.setEndDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.rfq.v1.GetListRFQResquest.prototype.hasEndDate = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.qis.rfq.v1.GetListRFQResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.rfq.v1.GetListRFQResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.rfq.v1.GetListRFQResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.rfq.v1.GetListRFQResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.rfq.v1.GetListRFQResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    rfqList: jspb.Message.toObjectList(msg.getRfqList(),
    qis_rfq_v1_rfq_pb.RFQ.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.rfq.v1.GetListRFQResponse}
 */
proto.qis.rfq.v1.GetListRFQResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.rfq.v1.GetListRFQResponse;
  return proto.qis.rfq.v1.GetListRFQResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.rfq.v1.GetListRFQResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.rfq.v1.GetListRFQResponse}
 */
proto.qis.rfq.v1.GetListRFQResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new qis_rfq_v1_rfq_pb.RFQ;
      reader.readMessage(value,qis_rfq_v1_rfq_pb.RFQ.deserializeBinaryFromReader);
      msg.addRfq(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.rfq.v1.GetListRFQResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.rfq.v1.GetListRFQResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.rfq.v1.GetListRFQResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.rfq.v1.GetListRFQResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRfqList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      qis_rfq_v1_rfq_pb.RFQ.serializeBinaryToWriter
    );
  }
};


/**
 * repeated RFQ rfq = 1;
 * @return {!Array<!proto.qis.rfq.v1.RFQ>}
 */
proto.qis.rfq.v1.GetListRFQResponse.prototype.getRfqList = function() {
  return /** @type{!Array<!proto.qis.rfq.v1.RFQ>} */ (
    jspb.Message.getRepeatedWrapperField(this, qis_rfq_v1_rfq_pb.RFQ, 1));
};


/**
 * @param {!Array<!proto.qis.rfq.v1.RFQ>} value
 * @return {!proto.qis.rfq.v1.GetListRFQResponse} returns this
*/
proto.qis.rfq.v1.GetListRFQResponse.prototype.setRfqList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.qis.rfq.v1.RFQ=} opt_value
 * @param {number=} opt_index
 * @return {!proto.qis.rfq.v1.RFQ}
 */
proto.qis.rfq.v1.GetListRFQResponse.prototype.addRfq = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.qis.rfq.v1.RFQ, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qis.rfq.v1.GetListRFQResponse} returns this
 */
proto.qis.rfq.v1.GetListRFQResponse.prototype.clearRfqList = function() {
  return this.setRfqList([]);
};


goog.object.extend(exports, proto.qis.rfq.v1);

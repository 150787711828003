require('moment-timezone');

export function convertDateTime(timestamp: any) {
  if (timestamp != undefined) {
    var time = new Date(Date.UTC(1970, 0, 1)); // Epoch
    time.setUTCSeconds(timestamp.seconds)
    return time.toLocaleString("pt-BR");
  }
  return "-";
}

export function convertUTCDateTime(timestamp: any) {
  if (timestamp != undefined) {
    var time = new Date(Date.UTC(1970, 0, 1)); // Epoch
    time.setUTCSeconds(timestamp.seconds)
    return time.toUTCString();
  }
  return "-";
}

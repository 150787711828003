// source: qis/transaction/v1/transaction_api.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_api_annotations_pb = require('../../../google/api/annotations_pb.js');
goog.object.extend(proto, google_api_annotations_pb);
var qis_transaction_v1_pix_pb = require('../../../qis/transaction/v1/pix_pb.js');
goog.object.extend(proto, qis_transaction_v1_pix_pb);
var qis_transaction_v1_transaction_pb = require('../../../qis/transaction/v1/transaction_pb.js');
goog.object.extend(proto, qis_transaction_v1_transaction_pb);
goog.exportSymbol('proto.qis.transaction.v1.CreateInvoicePIXForRFQRequest', null, global);
goog.exportSymbol('proto.qis.transaction.v1.CreateInvoicePIXForRFQResponse', null, global);
goog.exportSymbol('proto.qis.transaction.v1.FulFilTransactionRequest', null, global);
goog.exportSymbol('proto.qis.transaction.v1.FulFillTransactionResponse', null, global);
goog.exportSymbol('proto.qis.transaction.v1.GetTransactionByExternalIdRequest', null, global);
goog.exportSymbol('proto.qis.transaction.v1.GetTransactionRequest', null, global);
goog.exportSymbol('proto.qis.transaction.v1.GetTransactionResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.transaction.v1.CreateInvoicePIXForRFQRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qis.transaction.v1.CreateInvoicePIXForRFQRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.transaction.v1.CreateInvoicePIXForRFQRequest.displayName = 'proto.qis.transaction.v1.CreateInvoicePIXForRFQRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.transaction.v1.CreateInvoicePIXForRFQResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qis.transaction.v1.CreateInvoicePIXForRFQResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.transaction.v1.CreateInvoicePIXForRFQResponse.displayName = 'proto.qis.transaction.v1.CreateInvoicePIXForRFQResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.transaction.v1.FulFilTransactionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qis.transaction.v1.FulFilTransactionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.transaction.v1.FulFilTransactionRequest.displayName = 'proto.qis.transaction.v1.FulFilTransactionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.transaction.v1.FulFillTransactionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qis.transaction.v1.FulFillTransactionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.transaction.v1.FulFillTransactionResponse.displayName = 'proto.qis.transaction.v1.FulFillTransactionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.transaction.v1.GetTransactionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qis.transaction.v1.GetTransactionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.transaction.v1.GetTransactionRequest.displayName = 'proto.qis.transaction.v1.GetTransactionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.transaction.v1.GetTransactionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qis.transaction.v1.GetTransactionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.transaction.v1.GetTransactionResponse.displayName = 'proto.qis.transaction.v1.GetTransactionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.transaction.v1.GetTransactionByExternalIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qis.transaction.v1.GetTransactionByExternalIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.transaction.v1.GetTransactionByExternalIdRequest.displayName = 'proto.qis.transaction.v1.GetTransactionByExternalIdRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.transaction.v1.CreateInvoicePIXForRFQRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.transaction.v1.CreateInvoicePIXForRFQRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.transaction.v1.CreateInvoicePIXForRFQRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.transaction.v1.CreateInvoicePIXForRFQRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    document: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    rfqId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    device: (f = msg.getDevice()) && qis_transaction_v1_transaction_pb.Device.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.transaction.v1.CreateInvoicePIXForRFQRequest}
 */
proto.qis.transaction.v1.CreateInvoicePIXForRFQRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.transaction.v1.CreateInvoicePIXForRFQRequest;
  return proto.qis.transaction.v1.CreateInvoicePIXForRFQRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.transaction.v1.CreateInvoicePIXForRFQRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.transaction.v1.CreateInvoicePIXForRFQRequest}
 */
proto.qis.transaction.v1.CreateInvoicePIXForRFQRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocument(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRfqId(value);
      break;
    case 5:
      var value = new qis_transaction_v1_transaction_pb.Device;
      reader.readMessage(value,qis_transaction_v1_transaction_pb.Device.deserializeBinaryFromReader);
      msg.setDevice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.transaction.v1.CreateInvoicePIXForRFQRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.transaction.v1.CreateInvoicePIXForRFQRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.transaction.v1.CreateInvoicePIXForRFQRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.transaction.v1.CreateInvoicePIXForRFQRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocument();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRfqId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDevice();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      qis_transaction_v1_transaction_pb.Device.serializeBinaryToWriter
    );
  }
};


/**
 * optional string document = 1;
 * @return {string}
 */
proto.qis.transaction.v1.CreateInvoicePIXForRFQRequest.prototype.getDocument = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.transaction.v1.CreateInvoicePIXForRFQRequest} returns this
 */
proto.qis.transaction.v1.CreateInvoicePIXForRFQRequest.prototype.setDocument = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.qis.transaction.v1.CreateInvoicePIXForRFQRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.transaction.v1.CreateInvoicePIXForRFQRequest} returns this
 */
proto.qis.transaction.v1.CreateInvoicePIXForRFQRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.qis.transaction.v1.CreateInvoicePIXForRFQRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.transaction.v1.CreateInvoicePIXForRFQRequest} returns this
 */
proto.qis.transaction.v1.CreateInvoicePIXForRFQRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string rfq_id = 4;
 * @return {string}
 */
proto.qis.transaction.v1.CreateInvoicePIXForRFQRequest.prototype.getRfqId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.transaction.v1.CreateInvoicePIXForRFQRequest} returns this
 */
proto.qis.transaction.v1.CreateInvoicePIXForRFQRequest.prototype.setRfqId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional Device device = 5;
 * @return {?proto.qis.transaction.v1.Device}
 */
proto.qis.transaction.v1.CreateInvoicePIXForRFQRequest.prototype.getDevice = function() {
  return /** @type{?proto.qis.transaction.v1.Device} */ (
    jspb.Message.getWrapperField(this, qis_transaction_v1_transaction_pb.Device, 5));
};


/**
 * @param {?proto.qis.transaction.v1.Device|undefined} value
 * @return {!proto.qis.transaction.v1.CreateInvoicePIXForRFQRequest} returns this
*/
proto.qis.transaction.v1.CreateInvoicePIXForRFQRequest.prototype.setDevice = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.transaction.v1.CreateInvoicePIXForRFQRequest} returns this
 */
proto.qis.transaction.v1.CreateInvoicePIXForRFQRequest.prototype.clearDevice = function() {
  return this.setDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.transaction.v1.CreateInvoicePIXForRFQRequest.prototype.hasDevice = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.transaction.v1.CreateInvoicePIXForRFQResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.transaction.v1.CreateInvoicePIXForRFQResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.transaction.v1.CreateInvoicePIXForRFQResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.transaction.v1.CreateInvoicePIXForRFQResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    transactionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pixImage: (f = msg.getPixImage()) && qis_transaction_v1_pix_pb.PIXImage.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.transaction.v1.CreateInvoicePIXForRFQResponse}
 */
proto.qis.transaction.v1.CreateInvoicePIXForRFQResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.transaction.v1.CreateInvoicePIXForRFQResponse;
  return proto.qis.transaction.v1.CreateInvoicePIXForRFQResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.transaction.v1.CreateInvoicePIXForRFQResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.transaction.v1.CreateInvoicePIXForRFQResponse}
 */
proto.qis.transaction.v1.CreateInvoicePIXForRFQResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionId(value);
      break;
    case 2:
      var value = new qis_transaction_v1_pix_pb.PIXImage;
      reader.readMessage(value,qis_transaction_v1_pix_pb.PIXImage.deserializeBinaryFromReader);
      msg.setPixImage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.transaction.v1.CreateInvoicePIXForRFQResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.transaction.v1.CreateInvoicePIXForRFQResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.transaction.v1.CreateInvoicePIXForRFQResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.transaction.v1.CreateInvoicePIXForRFQResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransactionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPixImage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      qis_transaction_v1_pix_pb.PIXImage.serializeBinaryToWriter
    );
  }
};


/**
 * optional string transaction_id = 1;
 * @return {string}
 */
proto.qis.transaction.v1.CreateInvoicePIXForRFQResponse.prototype.getTransactionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.transaction.v1.CreateInvoicePIXForRFQResponse} returns this
 */
proto.qis.transaction.v1.CreateInvoicePIXForRFQResponse.prototype.setTransactionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional PIXImage pix_image = 2;
 * @return {?proto.qis.transaction.v1.PIXImage}
 */
proto.qis.transaction.v1.CreateInvoicePIXForRFQResponse.prototype.getPixImage = function() {
  return /** @type{?proto.qis.transaction.v1.PIXImage} */ (
    jspb.Message.getWrapperField(this, qis_transaction_v1_pix_pb.PIXImage, 2));
};


/**
 * @param {?proto.qis.transaction.v1.PIXImage|undefined} value
 * @return {!proto.qis.transaction.v1.CreateInvoicePIXForRFQResponse} returns this
*/
proto.qis.transaction.v1.CreateInvoicePIXForRFQResponse.prototype.setPixImage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.transaction.v1.CreateInvoicePIXForRFQResponse} returns this
 */
proto.qis.transaction.v1.CreateInvoicePIXForRFQResponse.prototype.clearPixImage = function() {
  return this.setPixImage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.transaction.v1.CreateInvoicePIXForRFQResponse.prototype.hasPixImage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.transaction.v1.FulFilTransactionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.transaction.v1.FulFilTransactionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.transaction.v1.FulFilTransactionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.transaction.v1.FulFilTransactionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    transactionId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.transaction.v1.FulFilTransactionRequest}
 */
proto.qis.transaction.v1.FulFilTransactionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.transaction.v1.FulFilTransactionRequest;
  return proto.qis.transaction.v1.FulFilTransactionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.transaction.v1.FulFilTransactionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.transaction.v1.FulFilTransactionRequest}
 */
proto.qis.transaction.v1.FulFilTransactionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.transaction.v1.FulFilTransactionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.transaction.v1.FulFilTransactionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.transaction.v1.FulFilTransactionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.transaction.v1.FulFilTransactionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransactionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string transaction_id = 1;
 * @return {string}
 */
proto.qis.transaction.v1.FulFilTransactionRequest.prototype.getTransactionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.transaction.v1.FulFilTransactionRequest} returns this
 */
proto.qis.transaction.v1.FulFilTransactionRequest.prototype.setTransactionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.transaction.v1.FulFillTransactionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.transaction.v1.FulFillTransactionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.transaction.v1.FulFillTransactionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.transaction.v1.FulFillTransactionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    transaction: (f = msg.getTransaction()) && qis_transaction_v1_transaction_pb.Transaction.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.transaction.v1.FulFillTransactionResponse}
 */
proto.qis.transaction.v1.FulFillTransactionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.transaction.v1.FulFillTransactionResponse;
  return proto.qis.transaction.v1.FulFillTransactionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.transaction.v1.FulFillTransactionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.transaction.v1.FulFillTransactionResponse}
 */
proto.qis.transaction.v1.FulFillTransactionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new qis_transaction_v1_transaction_pb.Transaction;
      reader.readMessage(value,qis_transaction_v1_transaction_pb.Transaction.deserializeBinaryFromReader);
      msg.setTransaction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.transaction.v1.FulFillTransactionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.transaction.v1.FulFillTransactionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.transaction.v1.FulFillTransactionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.transaction.v1.FulFillTransactionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransaction();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      qis_transaction_v1_transaction_pb.Transaction.serializeBinaryToWriter
    );
  }
};


/**
 * optional Transaction transaction = 1;
 * @return {?proto.qis.transaction.v1.Transaction}
 */
proto.qis.transaction.v1.FulFillTransactionResponse.prototype.getTransaction = function() {
  return /** @type{?proto.qis.transaction.v1.Transaction} */ (
    jspb.Message.getWrapperField(this, qis_transaction_v1_transaction_pb.Transaction, 1));
};


/**
 * @param {?proto.qis.transaction.v1.Transaction|undefined} value
 * @return {!proto.qis.transaction.v1.FulFillTransactionResponse} returns this
*/
proto.qis.transaction.v1.FulFillTransactionResponse.prototype.setTransaction = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.transaction.v1.FulFillTransactionResponse} returns this
 */
proto.qis.transaction.v1.FulFillTransactionResponse.prototype.clearTransaction = function() {
  return this.setTransaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.transaction.v1.FulFillTransactionResponse.prototype.hasTransaction = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.transaction.v1.GetTransactionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.transaction.v1.GetTransactionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.transaction.v1.GetTransactionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.transaction.v1.GetTransactionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    transactionId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.transaction.v1.GetTransactionRequest}
 */
proto.qis.transaction.v1.GetTransactionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.transaction.v1.GetTransactionRequest;
  return proto.qis.transaction.v1.GetTransactionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.transaction.v1.GetTransactionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.transaction.v1.GetTransactionRequest}
 */
proto.qis.transaction.v1.GetTransactionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.transaction.v1.GetTransactionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.transaction.v1.GetTransactionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.transaction.v1.GetTransactionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.transaction.v1.GetTransactionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransactionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string transaction_id = 1;
 * @return {string}
 */
proto.qis.transaction.v1.GetTransactionRequest.prototype.getTransactionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.transaction.v1.GetTransactionRequest} returns this
 */
proto.qis.transaction.v1.GetTransactionRequest.prototype.setTransactionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.transaction.v1.GetTransactionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.transaction.v1.GetTransactionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.transaction.v1.GetTransactionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.transaction.v1.GetTransactionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    transaction: (f = msg.getTransaction()) && qis_transaction_v1_transaction_pb.Transaction.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.transaction.v1.GetTransactionResponse}
 */
proto.qis.transaction.v1.GetTransactionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.transaction.v1.GetTransactionResponse;
  return proto.qis.transaction.v1.GetTransactionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.transaction.v1.GetTransactionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.transaction.v1.GetTransactionResponse}
 */
proto.qis.transaction.v1.GetTransactionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new qis_transaction_v1_transaction_pb.Transaction;
      reader.readMessage(value,qis_transaction_v1_transaction_pb.Transaction.deserializeBinaryFromReader);
      msg.setTransaction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.transaction.v1.GetTransactionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.transaction.v1.GetTransactionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.transaction.v1.GetTransactionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.transaction.v1.GetTransactionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransaction();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      qis_transaction_v1_transaction_pb.Transaction.serializeBinaryToWriter
    );
  }
};


/**
 * optional Transaction transaction = 1;
 * @return {?proto.qis.transaction.v1.Transaction}
 */
proto.qis.transaction.v1.GetTransactionResponse.prototype.getTransaction = function() {
  return /** @type{?proto.qis.transaction.v1.Transaction} */ (
    jspb.Message.getWrapperField(this, qis_transaction_v1_transaction_pb.Transaction, 1));
};


/**
 * @param {?proto.qis.transaction.v1.Transaction|undefined} value
 * @return {!proto.qis.transaction.v1.GetTransactionResponse} returns this
*/
proto.qis.transaction.v1.GetTransactionResponse.prototype.setTransaction = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.transaction.v1.GetTransactionResponse} returns this
 */
proto.qis.transaction.v1.GetTransactionResponse.prototype.clearTransaction = function() {
  return this.setTransaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.transaction.v1.GetTransactionResponse.prototype.hasTransaction = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.transaction.v1.GetTransactionByExternalIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.transaction.v1.GetTransactionByExternalIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.transaction.v1.GetTransactionByExternalIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.transaction.v1.GetTransactionByExternalIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    externalId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.transaction.v1.GetTransactionByExternalIdRequest}
 */
proto.qis.transaction.v1.GetTransactionByExternalIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.transaction.v1.GetTransactionByExternalIdRequest;
  return proto.qis.transaction.v1.GetTransactionByExternalIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.transaction.v1.GetTransactionByExternalIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.transaction.v1.GetTransactionByExternalIdRequest}
 */
proto.qis.transaction.v1.GetTransactionByExternalIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.transaction.v1.GetTransactionByExternalIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.transaction.v1.GetTransactionByExternalIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.transaction.v1.GetTransactionByExternalIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.transaction.v1.GetTransactionByExternalIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExternalId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string external_id = 1;
 * @return {string}
 */
proto.qis.transaction.v1.GetTransactionByExternalIdRequest.prototype.getExternalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.transaction.v1.GetTransactionByExternalIdRequest} returns this
 */
proto.qis.transaction.v1.GetTransactionByExternalIdRequest.prototype.setExternalId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


goog.object.extend(exports, proto.qis.transaction.v1);

import { GetTransactionByExternalIdRequest } from '../proto/qis/transaction/v1/transaction_api_pb';
import { TransactionServicePromiseClient } from '../proto/qis/transaction/v1/transaction_api_grpc_web_pb';
import { AuthorizationInterceptor } from './interceptors/authorization_interceptor';

export default class TransactionService {
    
    constructor (private _transactionService:TransactionServicePromiseClient = new TransactionServicePromiseClient(process.env.VUE_APP_GRPC_URL, null, {'unaryInterceptors': [new AuthorizationInterceptor()]})) {
    }

    async GetTransactionByExternalId(data:string) {
        const request = new GetTransactionByExternalIdRequest();
        request.setExternalId(data);
        try {
            return await this._transactionService.getTransactionByExternalId(request, {});
        } catch (err) {
            throw err
        }
    }

}


import Tabs from "@/components/Tabs/Tabs.vue";
import TabPane from "@/components/Tabs/TabPane.vue";
import { VBTooltip } from "bootstrap-vue/esm/directives/tooltip/tooltip";
import { VBPopover } from "bootstrap-vue/esm/directives/popover/popover";
import Badge from '@/components/Badge.vue';
import {RFQStatus, RFQSide} from "../../model/RFQModel";
import {OrderStatus} from "../../model/OrderModel";
import {MoneyCode, CryptoCode} from "../../model/Regional";
import {CryptoNetwork} from "../../model/CryptoModel";
import { convertDateTime, convertUTCDateTime} from '@/services/protoUtils';
import { TransactionStatus } from '../../model/TransactionModel';
import { unix } from "moment";

export default {
    name: "modal-rfq",
    components: {
        Tabs,
        TabPane,
        Badge,
    },
    directives: {
        BTooltip: VBTooltip,
        BPopover: VBPopover,
    },
    props: {
        data: {} as any,
    },
    data() {
        return {
           RFQStatus: RFQStatus,
           RFQSide: RFQSide,
           MoneyCode: MoneyCode,
           CryptoCode: CryptoCode,
           CryptoNetwork: CryptoNetwork,
           OrderStatus: OrderStatus,
           TransactionStatus: TransactionStatus,
        }
    },
    beforeDestroy() {
    },
    beforeMount() {
    },
    mounted() {
    },
    created() {
    },
    destroyed() {
    },
    methods: {
        computedCreateTime(timestamp: any) {
            return convertDateTime(timestamp);
        },
        computedUTCDateTime(timestamp: any) {
            return convertUTCDateTime(timestamp);
        },
    },
    computed: {

    },
    watch: {
        data: {
            handler (val, oldVal) {
            },
            deep: true
        }
    }
};

